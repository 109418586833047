module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ben-eisenberg","short_name":"Ben","start_url":"https://eisenberg.io","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/assets/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-166895484-1","cookieName":"gatsby-gdpr-google-analytics","anonymize":true},"googleTagManager":{"trackingId":"","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-166895484-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
